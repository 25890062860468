
<template>
  <div class="BusinNav_I">
    <div class="headNavs">
      <div class="headTilte">业务导航</div>
      <div class="headDetails">说明：包含销售、材料、运营等常见业务高度相关的报名网站、查询网址、素菜网站、考试网站、学习网站。</div>
    </div>

    <div class="BusinessNav">
      <div class="TableIpts"> 
        <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入名称">
        <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        <div class="NavLiReset" @click="showAdd()" v-if="TextMoMo('workbench:worknav:category:add')"><i class="el-icon-plus"></i>创建导航</div>
      </div>
    </div>

    <div class="BusinessNav">
      <div class="BusinessNavLi GGGG">
        <div style="min-width: 100px;">导航类别：</div>
        <div class="pppp">
          <div class="div" v-for="(item,i) in categoryList" :key="i" :class="item.id == idw ? 'Active' : ''" @click="D1(item.id)">{{item.name}}</div>
          <div class="div Active" @click="modular(1)">管理业务模块</div>
        </div>
      </div>
      <div class="BusinessNavLi">
        <div style="min-width: 100px;">导航模块：</div>
        <div class="pppp">
          <div class="div" :class="0 == ids ? 'Active' : ''" @click="D2(0)">全部模块</div>
          <div class="div" v-for="(item,i) in moduleList" :key="i" :class="item.id == ids ? 'Active' : ''" @click="D2(item.id)">{{item.name}}</div>
          <div class="div Active" @click="modular(2)">管理业务模块</div>
        </div>
      </div>
    </div>

    <div class="BusinessNav" v-for="item in navListData" :key="item.id">
      <div class="HHH">
        <div class="HHHname">{{item.name}}</div>
      </div>
      <ul class="tab_t2">
        <li class="li" v-for="items in item.worknav_list" :key="items.id">
          <span></span>
          <a class="A1" :href="items.url" target="_blank">{{items.title}}</a>
          <a class="A2" target="black" :href="items.note_url" v-if="items.note_type == 'url' && items.note_url != ''">[{{items.note_name}}]</a>
          <el-tooltip class="item" effect="dark" :content="items.note_content" placement="top" width="60" v-if="items.note_type == 'text'">
            <text >{{items.note_name}}</text>
          </el-tooltip>
          <el-dropdown trigger="click">
            <i class="el-icon-more"></i>
            <template #dropdown>
              <el-dropdown-menu class="menu">
                <el-dropdown-item class="menu__item" @click="HandleEdit(items)" v-if="TextMoMo('workbench:worknav:category:edit')">修改</el-dropdown-item>
                <el-dropdown-item class="menu__item" @click="HandleDel(items)" v-if="TextMoMo('workbench:worknav:category:del')">删除</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
      </ul>
    </div>


    <el-dialog :title="titleMath" v-model="dialogVisible" width="36%" :before-close="headeColse1">
      <el-form class="EditNewForm" style="padding:0 24px" label-width="100px">
        <el-form-item class="EditNewFormBtns" label="导航分类：">
          <div style="display:flex">
            <el-select v-model="value" placeholder="请选择" @change="Chang_ses">
              <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.name"></el-option>
            </el-select>
            <el-select  v-model="value2" placeholder="请选择" @change="Chang_ses2" style="margin-left:25px">
              <el-option  v-for="item in moduleList2" :key="item.id" :label="item.name" :value="item.name" ></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="网站名称：">
          <el-input v-model="Row.title" placeholder="请填写网站名称，如：豆腐网"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="网站网址：">
          <el-input v-model="Row.url" placeholder="请填写网站网站，如：www.douf.net"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="导航权重：">
          <el-input v-model="Row.weigh" type="number" maxlength='3' placeholder="请填写网站网站，如：1、 2 、3"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="注释类型：">
          <el-radio-group v-model="Row.note_type" style="margin-top:12px">
            <el-radio v-model="Row.note_type" label="none">无注释</el-radio> 
            <el-radio v-model="Row.note_type" label="url">添加外部链接</el-radio>
            <el-radio v-model="Row.note_type" label="text">添加文字注释</el-radio>
          </el-radio-group>
        </el-form-item>
        
        <div v-if="Row.note_type == 'url'" style="width:100%">
          <el-form-item class="EditNewFormBtns" label="注释名称：">
            <el-input v-model="Row.note_name" placeholder="请填写注释名称，如：教程、指南、说明"></el-input>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" label="注释标题：">
            <el-input v-model="Row.note_title" placeholder="请填写标题，如：南京个人社保该如何打印？"></el-input>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" label="注释链接：">
            <el-input v-model="Row.note_url" placeholder="请填写网站网站，如：www.douf.net"></el-input>
          </el-form-item>
        </div>
        
        <div v-if="Row.note_type == 'text'" style="width:100%">
          <el-form-item class="EditNewFormBtns" label="注释名称：">
            <el-input v-model="Row.note_name" placeholder="请填写注释名称，如：教程、指南、说明"></el-input>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" label="注释内容：">
            <el-input type="textarea" v-model="Row.note_content" placeholder="这里写下本网站是做什么的，能解决什么问题，包含了哪些信息等"></el-input>
          </el-form-item>
        </div>
        <el-form-item class="EditNewFormBtns">
          <div class="FormBtns">
            <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
            <div v-if="addPanel == false" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
            <div class="FormBtnsClose" @click="Close()">取消</div>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--职称管理-->
    <el-dialog title="导航类别管理" v-model="editVisible3" width="35.3%" :before-close="headeColse">
      <div class="Tabbl">
        <el-table
        :data="CGata"
        class="table"
        header-cell-class-name="table-header">
        <el-table-column label="导航类别" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.name" placeholder="类别"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'" @click="AS(scope.row)">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" v-if="isDel == false" @click="handleDelete2(scope.row)"></i>
                <i class="el-icon-delete" v-if="isDel == true"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="YADD" v-if="isYADD == 1" @click="Add_leibie2(1)"><i class="el-icon-plus"></i>创建类别</div>
      <div class="YADD" v-if="isYADD == 2" @click="Add_leibie2(2)"><i class="el-icon-plus"></i>创建类别1</div>
      <div class="Dityo">
        <div class="dialog-footer">
          <div v-if="isDelete == false" style="float:right">
            <el-button v-if="isYADD == 1" type="primary" @click="NabCH_BTN(1)" style="margin-right:24px">确 定</el-button>
            <el-button v-if="isYADD == 2" type="primary" @click="NabCH_BTN(2)" style="margin-right:24px">确 定</el-button>
          </div>
          <div v-if="isDelete == true" style="float:right">
            <el-button type="primary" @click="NabCH_BTN(3)" style="margin-right:24px">确 定2</el-button>
          </div>

        </div>
      </div>  
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue"; 
import ThirdPartySettingsVue from '../Configuration/ThirdPartySettings.vue';
export default {
  name: "basetable",
  data() {
    return {
      search: {category_id: "",keyword:''},
      page: {currentPage: 1,pageSize: 10},// 分页
      Row:{
        category_id:'',
        module_id:'',
        title:'',
        url:'',
        is_note:'',
        note_type: 'none',
        note_name:'',
        note_title:'',
        note_url:'',
        note_content:'',
        weigh:'',
      },
      categoryList:[],
      moduleList:[],
      navList:[],
      moduleList2:[],
      CGata:[],
      CGatadd:{},

      categoryList1:[],
      moduleList1:[],
      navList1:[],
      navListData:[],
      idw:0,
      ids:0,
      isYADD:'',
      titleMath:'',
      value:'',
      value2 :'',
      editId:'',
      CGataName:'',
      isDelete:false,
      dialogVisible:false,
      addPanel:false,
      isDel:false,
      editVisible3:false,
    };
  },
  mounted() {
    var that = this
    that.getCategory()
  },
  methods: {
    showAdd(){
      var that = this
      that.titleMath = '创建导航'
      that.dialogVisible = true
      that.addPanel = true
      that.Row.note_type = 'none'
      that.Row = {
        category_id:'',
        module_id:'',
        title:'',
        url:'',
        is_note:'',
        note_type: 'none',
        note_name:'',
        note_title:'',
        note_url:'',
        note_content:'',
        weigh:'',
      }
      that.value = ''
      that.value2 = ''
    },
    BtnAdd(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/worknav/addNav',
        data: {
          row: that.Row
        },
      }).then(function (res) {
        console.log(res.data);
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.dialogVisible = false
          that.getNavList()
        }
      })
    },
    HandleEdit(data){
      var that = this
      that.titleMath = '编辑导航'
      that.dialogVisible = true
      that.addPanel = false
      that.editId = data.id
      axios.get('/admin/workbench/worknav/navInfo', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row.category_id = res.data.data.row.category_id
          that.Row.module_id = res.data.data.row.module_id
          that.Row.title = res.data.data.row.title
          that.Row.url = res.data.data.row.url
          that.Row.is_note = res.data.data.row.is_note
          that.Row.note_type = res.data.data.row.note_type
          that.Row.note_name = res.data.data.row.note_name
          that.Row.note_title = res.data.data.row.note_title
          that.Row.note_url = res.data.data.row.note_url
          that.Row.note_content = res.data.data.row.note_content
          that.Row.weigh = res.data.data.row.weigh
          let cate = res.data.data.row.category_id
          let moid = res.data.data.row.module_id
          let index1 = that.categoryList.findIndex(obj => obj.id === cate)
          that.value = that.categoryList[index1].name

         
          axios.get('/admin/workbench/worknav/navList',{  
            params:{category_id: cate} 
          }).then(function (res) {
            that.moduleList2 = res.data.data.moduleList
            let index = that.moduleList2.findIndex(obj =>obj.id === moid)
            that.value2 = that.moduleList2[index].name
          })
        }
      })
    },
    BtnEdit(){
      var that = this
      axios({
        method: 'PUT',
        url: '/admin/workbench/worknav/editNav',
        data: {
          id:that.editId,
          row: that.Row
        },
      }).then(function (res) {
        console.log(res.data);
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.dialogVisible = false
          that.getNavList()
        }
      })
    },
    HandleDel(data){
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
            method: 'DELETE',
            url: '/admin/workbench/worknav/delNav',
            data: {
              id: data.id
            }
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.getNavList();
            }
        })
      })
    },
    modular(e){
      this.editVisible3 = true
      this.isDelete = false
      if(e == 1){
        this.CGata = this.categoryList1
        this.isYADD = 1
      }else{
        this.CGata = this.moduleList1
        this.isYADD = 2
      }
    },
    NabCH_BTN(e){
      var that = this
      if(e == 1){
        let index = that.categoryList1.length-1
        that.CGataName = that.categoryList1[index].name
        axios({
          method: 'post',
          url: '/admin/workbench/worknav/addCategory',
          data: {
            row:{
              name:that.CGataName
            }
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editVisible3 = false
            that.getCategory()
          }
        })
      }else if(e == 2){
        let index = that.moduleList1.length-1
        that.CGataName = that.moduleList1[index].name
        axios({
          method: 'post',
          url: '/admin/workbench/worknav/addModule',
          data: {
            row:{
              category_id:that.idw,
              name:that.CGataName
            }
          },
        }).then(function (res) {
          console.log(res.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {  
            that.$message.success(res.data.msg);
            that.editVisible3 = false
            that.getModuleList(that.idw)
            that.getNavList()
          }
        })
      }else if(e == 3){
        that.editVisible3 = false
        that.getModuleList(that.idw)
        that.getCategory()
      }
    },
    Add_leibie2(e) {  //创建导航类别
      var that = this
      that.isDel = true
      if(e == 1){
        var ATT = that.categoryList1
        var srt = {
          name:'新加',
          content: "新加",
          status: 1,
          type: "custom",
          value:0,
        }
        ATT.push(srt)
        that.categoryList1 = ATT
      }else if(e == 2){
        var ATT = that.moduleList1
        var srt = {
          name:'新加',
          content: "新加",
          status: 1,
          type: "custom",
          value:0,
        }
        ATT.push(srt)
        that.moduleList1 = ATT
      }
    },
    handleDelete2(data) {  // 删除导航类别
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        that.isDelete = true
        var ATT = that.CGata
        let index = ATT.findIndex(obj => obj.id  === data.id)
        ATT.splice(index, 1)
        that.CGata = ATT
        if(that.isYADD == 1){
           axios({
            method: 'DELETE',
            url: '/admin/workbench/worknav/delCategory',
            data: {
              id: data.id
            }
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
            }
          })
        }else if(that.isYADD == 2){
          axios({
            method: 'DELETE',
            url: '/admin/workbench/worknav/delModule',
            data: {
              id: data.id
            }
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
            }
          })
        }
      })
    },
    headeColse(){
      var that = this
      that.getModuleList(that.idw)
      that.getCategory()
      that.editVisible3 = false
    },
    headeColse1(){
      var that = this
      that.Row = {}
      that.value = ''
      that.value2 = ''
      that.dialogVisible = false
    },
    Chang_ses(data){
      var that = this
      var att = ''
      let index = that.categoryList.findIndex(obj => obj.name === data)
      that.Row.category_id = that.categoryList[index].id
      axios.get('/admin/workbench/worknav/navList',{  
        params:{category_id: that.Row.category_id} 
      }).then(function (res) {
        that.moduleList2 = res.data.data.moduleList
      })
      console.log(that.moduleList2);

    },
    Chang_ses2(data){
      var that = this
      var att = that.moduleList2
      att.forEach(item =>{
        if(item.name == data){
          that.Row.module_id = item.id
        }
      })
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    getCategory(){ // 类别
      var that = this
      axios.get('/admin/workbench/worknav/categoryList',{   
      }).then(function (res) {
        that.categoryList = res.data.data.list
        that.categoryList1 = [...res.data.data.list]
        that.idw = that.categoryList[0].id
        that.getModuleList(that.idw)
        that.getNavList()
      })
    },
    getModuleList(id){  // 模块
      var that = this
      axios.get('/admin/workbench/worknav/moduleList',{   
        params:{
          id: id
        }
      }).then(function (res) {
        that.moduleList = res.data.data.list
        that.moduleList1 = [...res.data.data.list]
      })
    },
    getNavList(){
      var that = this
      axios.get('/admin/workbench/worknav/navList',{  
        params:{category_id: that.idw || 1} 
      }).then(function (res) {
        that.navList = res.data.data.moduleList
        that.navList1 = [...res.data.data.moduleList]
        that.navListData = that.navList
      })
    },
    D1(id){
      var that = this
      that.idw = id
      that.ids = 0
      that.getNavList()
      that.getModuleList(id)
    },
    D2(id){
      var that = this
      that.ids = id
      let index = that.navList1.findIndex(obj => obj.id === id)
      var arr = []
      arr.push(that.navList1[index])
      that.navListData = arr
      if(index == -1){
        that.navListData = that.navList1
      }
    },
    Close(){
      this.dialogVisible = false
    }
  },
};
</script>

<style scoped>

.BusinessNav{
  padding: 24px;
  margin: 15px 24px;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
}
.TableIpts{
  display: flex;
  width: 50%;
  margin: 0 auto;
}
.TableIpts input{
  border-radius: 4px 0 0 4px;
  width: 60%;
  text-indent: 1.5em;
  height: 33px !important;
}
.NavLiReset{
  margin-left: 24px;
}
.NavLiReset i{
  margin-right:2px
}
.BusinessNavLi {
  min-height: 30px;
  line-height: 30px;
  display: flex;
}
.pppp{
  display: flex;
  flex-wrap: wrap;
}
.BusinessNavLi .div{
  margin-right: 34px;
  cursor: pointer;
}
.GGGG{
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px dashed #eee;
}
.Active{
  color:#1890FF;
  cursor: pointer;
}
.HHH{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.HHHname{
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #262626;
}
ul{
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
ul .li {
  padding-left: 12px;
  position: relative;
  width: 24%;
  margin-bottom: 20px;
}
ul .li a{
  color: #262626;
}
ul .li a:hover{
  color:#1890FF;
}
ul .li span{
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color:#616060;
  position: absolute;
  top: 7px;
  left: 0;
}
ul .li /deep/ .el-dropdown{
  float: right;
  margin-right: 30px;
  margin-top: 5px;
}
.el-icon-more{
  cursor: pointer;
  margin-left: 8px;
  position: relative;
  transform: rotate(90deg);
}
.menu{
  width: 70px !important;
  margin-top: 0;
}
.menu__item{
  width: 100%;
  margin-bottom: 0;
}

.tab_t2 a:hover {
  color: #1890FF!important;
}
.tab_t2 text {
  display: inline-block;
  height: 20px;
  overflow: hidden;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #f98d8d;
  margin-right: 13px;
}
.tab_t2 .A1{
  display: inline-block;
  max-width: 70%;
  height: 20px;
  overflow: hidden;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
  margin-right: 13px
}
.tab_t2 .A2{
  display: inline-block;
  height: 20px;
  overflow: hidden;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1890FF;
}
</style>